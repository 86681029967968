function Home() {
    return (
        <>
            <div>
                <h1 className="text-2xl 2xl:text-4xl text-center mt-10">About Us</h1>
                <div className="flex flex-col 2xl:flex-row">
                    <div className="hidden 2xl:mt-0 mt-8 w-full 2xl:w-full 2xl:flex justify-center items-center 2xl:justify-center">
                        <img alt="JDA Workshop" src="/media/jda_6524.jpg" className="w-1/3 2xl:w-full"></img>
                    </div>
                    <div>
                        <p className="text-lg 2xl:text-2xl text-center mt-8 lg:ml-32 lg:mr-32">
                            Jayna Dance Academy was founded by Jayna Patel, a freelance dancer and choreographer based in the Midlands, with over 10 years of professional experience in the field. Working in a diverse range of environments, Jayna has always enjoyed choreographing in a multitude of dance styles.
                        </p>
                        <div className="flex 2xl:mt-0 mt-8 w-full 2xl:w-full 2xl:hidden justify-center items-center 2xl:justify-center">
                            <img alt="JDA Workshop" src="/media/jda_6524.jpg" className="md:w-1/3 2xl:w-full"></img>
                        </div>
                        <p className="text-lg 2xl:text-2xl text-center mt-8 lg:ml-32 lg:mr-32">
                            Jayna Dance Academy runs a range of regular classes, alongside pop-up workshops, giving dancers the opportunity to experience Hip-Hop, Contemporary, Afro, Bollywood, Bellydance and Dancehall amongst many more styles.
                        </p>
                        <p className="text-lg 2xl:text-2xl text-center mt-8 lg:ml-32 lg:mr-32">
                            JDA aims to positively impact the dance journeys of as many people as possible, whether it be to boost confidence, improve mental health or simply get fit, and will continue to work closely with local communities to make a powerful difference.
                        </p>
                    </div>
                    <div className="2xl:mt-0 mt-8 w-full 2xl:w-full flex justify-center items-center 2xl:justify-center">
                        <img alt="JDA Event" src="/media/jda_4072.jpg" className="md:w-1/3 2xl:w-full"></img>
                    </div>
                </div>
                
            </div>
        </>
    );
}

export default Home;