import { Link } from 'react-router-dom';

function PageNotFound() {
    return (
        <>
            <div>
                <h1 className="text-4xl text-center mt-10">404</h1>
                <p className="text-2xl 2xl:text-2xl text-center mt-8 lg:ml-32 lg:mr-32">
                    The requested page was not found, let's get you back to the dance floor!
                    <div className="mt-8">
                        <Link to="/" className="text-xl underline">Return to Home</Link>
                    </div>
                </p>
            </div>
        </>
    );
}

export default PageNotFound;