function SummerCamp24() {
    return (
        <div className="m-8 p-6 lg:p-16 lg:pl-32 lg:pr-32 bg-white border-double border-8 border-sc24-yellow rounded-xl shadow-xl">
            <div className="text-sc24-purple december-show text-3xl md:text-6xl text-center">JDA SUMMER CAMP 2024</div>
            <p className="text-center text-lg md:text-2xl mt-4">
                Join us this summer from the 5th to the 9th of August at the Aakash Odedra Company Studios in Leicester!
            </p>
            <p className="text-center text-lg md:text-2xl mt-4">
                We'll be hosting sessions on Monday, Tuesday, Thursday and Friday, with afternoon sessions for Kids and evening sessions for Adults!
            </p>

            <div>
                <div className="text-sc24-purple december-show text-4xl text-center mt-8 lg:mt-16">Kids (5-12 yrs)</div>
                <p className="text-center text-lg md:text-2xl">
                    Kids, join us for dance, sports, games, activites & so much more!
                </p>
                <p className="text-sc24-purple december-show text-lg md:text-2xl text-center mt-4">
                    Timings
                </p>
                <div className="hidden md:flex justify-center">
                    <ul className="text-center text-lg md:text-2xl flex justify-center gap-4">
                        |<li><b>5th</b> - 1-5pm</li>
                        |<li><b>6th</b> - 1-5pm</li>
                        |<li><b>8th</b> - 1-5pm</li>
                        |<li><b>9th</b> - 1-5pm</li>
                        |
                    </ul>
                </div>
                <div className="flex md:hidden justify-center">
                    <ul className="text-center text-lg md:text-2xl justify-center gap-4">
                        <li><b>5th</b> - 1-5pm</li>
                        <li><b>6th</b> - 1-5pm</li>
                        <li><b>8th</b> - 1-5pm</li>
                        <li><b>9th</b> - 1-5pm</li>
                    </ul>
                </div>
                <p className="text-sc24-purple december-show text-lg md:text-2xl text-center mt-4">
                    Pricing
                </p>
                <div className="hidden md:flex justify-center">
                    <ul className="text-center text-lg md:text-2xl flex justify-center gap-4">
                        |<div className="inline flex flex-col"><li><b>All 4 days</b></li><li>£65</li></div>
                        |<div className="inline flex flex-col"><li><b>Any 3 days</b></li><li>£55</li></div>
                        |<div className="inline flex flex-col"><li><b>Any 2 days</b></li><li>£45</li></div>
                        |<div className="inline flex flex-col"><li><b>Just 1 day</b></li><li>£25</li></div>
                        |
                    </ul>
                </div>
                <div className="flex md:hidden justify-center">
                    <ul className="text-center text-lg md:text-2xl justify-center gap-4">
                        <li><b>All 4 days</b> - £65</li>
                        <li><b>Any 3 days</b> - £55</li>
                        <li><b>Any 2 days</b> - £45</li>
                        <li><b>Just 1 day</b> - £25</li>
                    </ul>
                </div>
            </div>

            <div>
                <div className="text-sc24-purple december-show text-4xl text-center mt-8 lg:mt-16">Adults (13+ yrs)</div>
                <p className="text-center text-lg md:text-2xl">
                    Adults, prepare for 7 power-packed Summer sessions led by 4 different teachers,<br />ranging from Indo-Contemporary to Gaana Dance...
                </p>
                <p className="text-sc24-purple december-show text-lg md:text-2xl text-center mt-4">
                    Schedule
                </p>
                <div className="flex justify-center">
                    <ul className="text-center text-lg md:text-2xl justify-center gap-4">
                        <li className="mt-4"><b>Day 1 (5th)</b> - 6-8:30pm<br />Bollywood (Chandni) & Afro Fusion (with Velash)</li>
                        <li className="mt-4"><b>Day 2 (6th)</b> - 6-8:30pm<br />Ballet (with Emma) & Semi-Classical (with Chandni)</li>
                        <li className="mt-4"><b>Day 3 (8th)</b> - 6-8:30pm<br />Gaana Dance (with Shay) & Hip-Hop (with Bhargav)</li>
                        <li className="mt-4"><b>Day 4 (9th)</b> - 6-8:30pm<br />Bollywood (with Sim & Shay)</li>
                    </ul>
                </div>
                <p className="text-sc24-purple december-show text-lg md:text-2xl text-center mt-4">
                    Pricing
                </p>
                <div className="hidden md:flex justify-center">
                    <ul className="text-center text-lg md:text-2xl flex justify-center gap-4">
                        |<div className="inline flex flex-col"><li><b>All 4 days</b></li><li>£80</li></div>
                        |<div className="inline flex flex-col"><li><b>Any 3 days</b></li><li>£65</li></div>
                        |<div className="inline flex flex-col"><li><b>Any 2 days</b></li><li>£45</li></div>
                        |<div className="inline flex flex-col"><li><b>Just 1 day</b></li><li>£25</li></div>
                        |
                    </ul>
                </div>
                <div className="flex md:hidden justify-center">
                    <ul className="text-center text-lg md:text-2xl justify-center gap-4">
                        <li><b>All 4 days</b> - £80</li>
                        <li><b>Any 3 days</b> - £65</li>
                        <li><b>Any 2 days</b> - £45</li>
                        <li><b>Just 1 day</b> - £25</li>
                    </ul>
                </div>
            </div>

            <p className="text-center text-lg md:text-2xl mt-8 lg:mt-16">
                To register your interest in any of the sessions listed above, DM @jaynadanceacademy on Instagram, or email us. (our email can be found at the bottom of this page)
            </p>

        </div>
    );
}

export default SummerCamp24;