function ContactUs() {
    return (
        <>
            <div>
                <h1 className="text-4xl text-center mt-10">Contact Us</h1>
                <h2 className="text-2xl text-center mt-8">Services we offer:</h2>
                <ul className="mt-4">
                    <li className="text-xl text-center">Wedding / Event Choreography</li>
                    <li className="text-xl text-center">Dancers for Events</li>
                    <li className="text-xl text-center">Community Workshops</li>
                    <li className="text-xl text-center">Promotional Content</li>
                    <li className="text-2xl text-center mt-4">Get in touch for any custom requests! </li>
                </ul>
                <p className="text-xl text-center mt-8">If you have any further enquiries, please get in touch with us via the contact methods listed below.</p>
            </div>
        </>
    );
}

export default ContactUs;