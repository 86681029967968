import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';

import Home from "./pages/Home";
import WhatsOn from "./pages/WhatsOn";
import Gallery from "./pages/Gallery";
import ContactUs from "./pages/ContactUs";
import PageNotFound from "./pages/PageNotFound";

import SummerCamp24 from './pages/events/SummerCamp24';

const menuPages = [
    { text: "Home", page: "", component: Home, hidden: false },
    { text: "What's On", page: "whats-on", component: WhatsOn, hidden: false },
    { text: "Gallery", page: "gallery", component: Gallery, hidden: false },
    { text: "Contact Us", page: "contact-us", component: ContactUs, hidden: true }
];

const whatsOnPages = [
    { text: "JDA Summer Camp 2024", page: "whats-on/summer-camp-24", component: SummerCamp24, className: "text-sc24-purple", hidden: false }
];

const socialLinks = [
    { name: "Instagram", icon: "/media/social-icons/instagram.png", url: "https://www.instagram.com/jaynadanceacademy" },
    { name: "Facebook", icon: "/media/social-icons/facebook.png", url: "https://www.facebook.com/jaynadanceacademy" },
    { name: "YouTube", icon: "/media/social-icons/youtube.png", url: "https://youtube.com/@jaynadanceacademy" }
];

function App() {
    return (
        <Router>
            <div className="min-h-screen p-4 lg:p-16">
                <Header />
                <hr className="mt-4 2xl:mt-12 border-t-4 border-black rounded-2xl"></hr>
                <Routes>
                    {(menuPages.concat(whatsOnPages)).map(item => (
                        <Route path={item.page} element={< item.component />} />
                    ))}
                    <Route path="*" element={< PageNotFound />} />
                </Routes>
            </div>
            <Footer />
        </Router>
    );
}

function Header() {
    return (
        <div className="flex flex-col 2xl:flex-row">
            <Link to="/">
                <div className="w-full 2xl:w-3/5 flex justify-center items-center 2xl:justify-start">
                    <img alt="Jayna Dance Academy Logo" src="/media/Logo.png" className="hidden 2xl:block w-1/6"></img>
                    <img alt="Jayna Dance Academy Long Text" src="/media/LongText.png" className="w-full 2xl:ml-8 2xl:w-3/5 block"></img>
                </div>
            </Link>
            <Navbar />
        </div>
    );
}

function Navbar() {
    const currentPage = useLocation();
    return (
        <div className="w-full 2xl:w-3/5 flex justify-evenly 2xl:justify-end items-center mt-8 2xl:mt-0">
            {menuPages.map(item => (
                !item.hidden && <Link key={item.page} to={item.page} className={`text-md md:text-lg lg:text-2xl 2xl:text-2xl text-center inline-flex m-4 cursor-pointer hover:text-gray-500 ${currentPage.pathname === "/" + item.page && "underline"}`}>{item.text}</Link>
            ))}
        </div>
    );
}

function Footer() {
    return (
        <div className="mt-4 lg:mt-0 bg-white shadow-inner p-8 flex flex-col md:flex-row items-center justify-between">
            <div className="w-full md:w-1/3 flex justify-center md:justify-start">
                <img alt="Jayna Dance Academy Logo" src="/media/Logo.png" className="w-20"></img>
            </div>
            <div className="w-full md:w-1/3 md:mt-0 mt-6 text-center">
                <p><a href="tel:07422533237" target="_blank" rel="noreferrer">07422 533237</a></p>
                <p><a href="mailto:contact@jaynadanceacademy.co.uk" target="_blank" rel="noreferrer">contact@jaynadanceacademy.co.uk</a></p>
            </div>
            <div className="w-full md:w-1/3 md:mt-0 mt-4 text-center md:text-right flex items-center justify-center md:justify-end">
                { socialLinks.map(item => (
                    <a href={item.url} target="_blank" rel="noreferrer">                
                        <img src={item.icon} alt={item.name} className="m-2 h-8"></img>
                    </a>
                )) }
            </div>
        </div>
    );
}

export default App;
