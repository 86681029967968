function Gallery() {
    return (
        <>
            <div>
                <h1 className="text-4xl text-center mt-10 mb-10">Gallery</h1>

                <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" rel="noreferrer" className="crt-logo crt-tag">Powered by Curator.io</a></div>
                
                {
                    (function(){
                    var i,e,d=document,s="script";i=d.createElement("script");i.async=1;i.charset="UTF-8";
                    i.src="https://cdn.curator.io/published/d0388e63-dbe0-4752-b6c1-de301ab9f901.js";
                    e=d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
                    })()
                }
            </div>
        </>
    );
}

export default Gallery;